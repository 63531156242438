var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-akkordeon-entry" }, [
    _c(
      "div",
      {
        staticClass: "webkit-akkordeon-title",
        on: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      },
      [
        _c("div", { staticClass: "webkit-akkordeon-title-text" }, [
          _vm._v(_vm._s(_vm.heading))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "webkit-akkordeon-icon-container" }, [
          _c("i", {
            class: {
              "webkit-minus webkit-akkordeon-active": _vm.show,
              "webkit-plus": !_vm.show
            },
            attrs: { "aria-hidden": "true" }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "akkordeonContainer",
        staticClass: "webkit-akkordeon-text-container",
        class: { "akkordeon-active": _vm.show }
      },
      [
        _c("div", {
          ref: "akkordeonText",
          staticClass: "webkit-akkordeon-text webkit-redactor-content",
          domProps: { innerHTML: _vm._s(_vm.text) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }