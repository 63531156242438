<template>
  <div class="webkit-grid-container-cell">
    <div
      class="webkit-grid-parent"
      v-bind:class="{
        'grid-cols-3-important': columns,
        'grid-cols-4-important': !columns,
        'grid-last-el-center': displayedBlocks.length % 3 && columns,
      }"
    >
      <a
        class="webkit-grid-item"
        v-bind:key="index"
        v-for="(block, index) in displayedBlocks"
        v-bind:href="block.url || false"
        v-bind:class="{
          'webkit-grid-item-second-last': displayedBlocks.length % 4 == 2 && index == displayedBlocks.length - 2,
          'webkit-grid-item-last': displayedBlocks.length % 4 == 2 && index == displayedBlocks.length - 1
        }"
        :target="block.target"
      >
        <div class="webkit-grid-item-grid">
          <div class="webkit-grid-cell-img">
            <div
              class="webkit-grid-image bg-img"
              v-if="block.image"
              v-bind:style="{
                backgroundImage: 'url(\'' + block.image + '\')',
              }"
            ></div>
          </div>
          <div class="webkit-grid-heading" v-html="block.heading"></div>
          <div class="webkit-grid-button-cell">
            <div class="webkit-grid-button" v-if="block.url">
              <div v-if="block.linkText" class="webkit-btn__big">
                {{ block.linkText }}
              </div>
              <i v-else class="webkit-btn__small webkit-arrow-right"></i>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div
      class="webkit-gallery-load-more-button-container"
      v-if="blocks.length > numberOfItemsToDisplay"
      v-on:click="showMore"
    >
      <div v-if="loadmorebutton" class="webkit-btn__big">
        {{ loadmorebutton }}
      </div>
      <i v-else class="webkit-btn__small webkit-plus">
        {{ loadmorebutton }}
      </i>
    </div>
  </div>
</template>

<script>
import Vue, { VNode } from 'vue';
import * as gridConfig from 'theme/modules/grid/theme.grid.config';

export default Vue.extend({
  name: 'WebkitGrid',
  props: {
    blocks: Array,
    columns: String,
    loadmorebutton: String,
  },
  data() {
    return {
      numberOfItemsToDisplay: gridConfig.loadMore.numberOfItemsToShow,
      increment: gridConfig.loadMore.numberOfItemsIncrement,
      displayedBlocks: [],
    };
  },
  methods: {
    showMore: function () {
      this.numberOfItemsToDisplay += this.increment;
      this.numberOfItemsToDisplay = Math.min(
        this.numberOfItemsToDisplay,
        this.blocks.length
      );
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
    },
  },
  created() {
    this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
  },
});
</script>
