import { VDirective, VServices } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';

export const subnav: VDirective = [
  'subnav',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ) {

      if ( binding.arg == 'activestate' ) {
        $( function () {
          let subNavItemIsActive = $( el ).find( '.sub-nav-active-link' ).length;
          if ( subNavItemIsActive ) {
            $( el ).find( '.webkit-header-navigation-link' ).addClass( 'webkit-header-navigation-activeLink' );
          }
        } );
      }

      if ( binding.arg == 'show' ) {
        el.addEventListener( 'click', function () {
          let subNav: JQuery<HTMLElement> = $( this ).parent().find( '.sub-nav' );
          let hideButton: JQuery<HTMLElement> = $( this ).parent().find( '.sub-nav-btn-hide' );

          if ( subNav.length ) {
            subNav.addClass( 'active-subnav' );
          }

          if ( hideButton.length ) {
            hideButton.show();
          }

          $( this ).hide();
        } );
      }

      if ( binding.arg == 'hide' ) {
        el.addEventListener( 'click', function () {
          let subNav: JQuery<HTMLElement> = $( this ).parent().find( '.sub-nav' );
          let showButton: JQuery<HTMLElement> = $( this ).parent().find( '.sub-nav-btn-show' );

          if ( subNav.length ) {
            subNav.removeClass( 'active-subnav' );
          }

          if ( showButton.length ) {
            showButton.show();
          }

          $( this ).hide();
        } );
      }
    },
  },
];
