import { VDirective, VServices } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';

export const overlay: VDirective = [
  'overlay',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ) {
      if ( binding.arg == 'show' ) {
        el.addEventListener( 'click', function () {
          console.log( $( this ).parent().parent('.webkit-header') );
          $( this ).parent().parent('.webkit-header').find( '.webkit-header-nav-overlay' ).addClass( 'overlay-active' );
          $( 'html' ).css( 'overflow', 'hidden' );
        } );
      }

      if ( binding.arg == 'hide' ) {
        el.addEventListener( 'click', function () {
          $( '.webkit-header-nav-overlay' ).removeClass( 'overlay-active' );
          $( 'html' ).css( 'overflow', 'auto' );
        } );
      }
    },
  },
];
