var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-newsletter-container" }, [
    _c("div", { staticClass: "webkit-newsletter-grid" }, [
      _c("div", { staticClass: "webkit-newsletter-cell" }, [
        _c("div", { staticClass: "webkit-newsletter" }, [
          _c("div", { staticClass: "webkit-newsletter-text" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "webkit-newsletter-form-container" }, [
            _c(
              "form",
              {
                staticClass: "webkit-newsletter-form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "webkit-newsletter-input",
                  attrs: { placeholder: "Email" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                !_vm.success & !_vm.waiting
                  ? _c(
                      "div",
                      {
                        staticClass: "webkit-newsletter-button",
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "webkit-newsletter-send-icon far fa-long-arrow-right"
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.success & !_vm.waiting
                  ? _c("div", { staticClass: "webkit-newsletter-button" }, [
                      _c("i", {
                        staticClass:
                          "webkit-newsletter-button-success far fa-check"
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.waiting
                  ? _c("div", { staticClass: "webkit-newsletter-button" }, [
                      _c("i", {
                        staticClass:
                          "fas fa-spinner-third webkit-loadingSpinner"
                      })
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("div", { staticClass: "webkit-newsletter-error" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.errorMessage) + "\n          "
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }