var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-grid-container-cell" }, [
    _c(
      "div",
      {
        staticClass: "webkit-grid-parent",
        class: {
          "grid-cols-3-important": _vm.columns,
          "grid-cols-4-important": !_vm.columns,
          "grid-last-el-center": _vm.displayedBlocks.length % 3 && _vm.columns
        }
      },
      _vm._l(_vm.displayedBlocks, function(block, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "webkit-grid-item",
            class: {
              "webkit-grid-item-second-last":
                _vm.displayedBlocks.length % 4 == 2 &&
                index == _vm.displayedBlocks.length - 2,
              "webkit-grid-item-last":
                _vm.displayedBlocks.length % 4 == 2 &&
                index == _vm.displayedBlocks.length - 1
            },
            attrs: { href: block.url || false, target: block.target }
          },
          [
            _c("div", { staticClass: "webkit-grid-item-grid" }, [
              _c("div", { staticClass: "webkit-grid-cell-img" }, [
                block.image
                  ? _c("div", {
                      staticClass: "webkit-grid-image bg-img",
                      style: {
                        backgroundImage: "url('" + block.image + "')"
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "webkit-grid-heading",
                domProps: { innerHTML: _vm._s(block.heading) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-button-cell" }, [
                block.url
                  ? _c("div", { staticClass: "webkit-grid-button" }, [
                      block.linkText
                        ? _c("div", { staticClass: "webkit-btn__big" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(block.linkText) +
                                "\n            "
                            )
                          ])
                        : _c("i", {
                            staticClass: "webkit-btn__small webkit-arrow-right"
                          })
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.blocks.length > _vm.numberOfItemsToDisplay
      ? _c(
          "div",
          {
            staticClass: "webkit-gallery-load-more-button-container",
            on: { click: _vm.showMore }
          },
          [
            _vm.loadmorebutton
              ? _c("div", { staticClass: "webkit-btn__big" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
              : _c("i", { staticClass: "webkit-btn__small webkit-plus" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }