var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "webkit-textGrid-background" }, [
      _c("div", { staticClass: "webkit-textGrid-container" }, [
        _c("div", { staticClass: "webkit-textGrid-container-cell" }, [
          _c(
            "div",
            { staticClass: "webkit-textGrid-parent" },
            _vm._l(_vm.displayedBlocks, function(block, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "webkit-textGrid-item",
                  attrs: { href: block.url || false, target: block.target }
                },
                [
                  _c("div", { staticClass: "webkit-textGrid-item-grid" }, [
                    _c("div", {
                      staticClass: "webkit-textGrid-cell-background"
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "webkit-textGrid-heading",
                      domProps: { innerHTML: _vm._s(block.heading) }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "webkit-textGrid-text",
                      domProps: { innerHTML: _vm._s(block.text) }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "webkit-textGrid-button-cell" }, [
                      block.url
                        ? _c("div", { staticClass: "webkit-textGrid-button" }, [
                            _c("i", {
                              staticClass:
                                "webkit-btn__small webkit-arrow-right"
                            })
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.blocks.length > _vm.numberOfItemsToDisplay
      ? _c(
          "div",
          {
            staticClass: "webkit-textGrid-load-more-button-container",
            on: { click: _vm.showMore }
          },
          [
            _vm.loadmorebutton
              ? _c("div", { staticClass: "webkit-btn__big" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
              : _c("i", { staticClass: "webkit-btn__small webkit-plus" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }